import { Notification } from '../../models/notification.model';
import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
  })
export class NotificationComponent {
  @Input() notification: Notification;

  /**
   * Cuando una notificación no es del día actual es oldNotification
  */
  @Input() oldNotification: boolean;

  @Output() click = new EventEmitter();

  onClick() {
    this.click.emit(this.notification);
  }

  deleteNotification(event: MouseEvent) {
    event.stopPropagation();
  }
}
