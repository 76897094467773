import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validate-phone-page',
  templateUrl: './validate-phone-page.component.html',
  styleUrls: ['./validate-phone-page.component.scss']
})
export class ValidatePhonePageComponent implements OnInit {
  public returnUrl: string;

  constructor(public route: ActivatedRoute) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }
}
