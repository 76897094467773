import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { lookUp } from 'src/app/_core/dictionary/error.dictionary';
import { formatCUIT } from '../../helpers/listRole';

@Component({
  selector: 'app-no-active-cuits-page',
  templateUrl: './no-active-cuits-page.component.html',
  styleUrls: ['./no-active-cuits-page.component.scss']
})
export class NoActiveCuitsPageComponent implements OnInit {
  msg: string;

  constructor(private userDataService: UserDataService) { }

  ngOnInit(): void {
    const cuits = this.userDataService.getUserCUITs();
    this.msg = lookUp('211')?.replace('<cuit_nro>', formatCUIT(cuits[0]?.cuit));
  }
}
