<div id="phone-form" class="box">
  <form [formGroup]="phoneForm">
    <h5 class="card-title">{{ messageOne }}</h5>
    <h5 class="card-title message-two">{{ messageTwo }}</h5>

    <div class="form-row">
      <!-- Phone Form -->
      <div *ngIf="firstStep" class="col-12">
        <div class="form-group" [class.custom-invalid]="isInvalid('phone')" [class.custom-valid]="isValid('phone')">
          <input type="text" class="form-control" formControlName="phone" id="phone"
            placeholder="Número de telefonía móvil" mask="0000000000" (focus)="clearMessages()" />
          <small class="feedback-error" *ngIf="isInvalid('phone')">El número debe ser sin el 0 y sin el 15.
          </small>
          <small class="feedback-error" *ngIf="showErrorMessage">{{ errorMessage }}
          </small>
        </div>
      </div>
      <!-- /Phone Form -->
      <!-- Code Form -->
      <div *ngIf="!firstStep" class="col-12">
        <div class="form-group" [class.custom-invalid]="isInvalid('code')" [class.custom-valid]="isValid('code')">
          <input type="text" class="form-control" formControlName="code" id="code" placeholder="Código"
            [attr.mask]="mask" (focus)="clearMessages()" />
          <small class="feedback-error" *ngIf="isInvalid('code')">Debe introducir un código válido.
          </small>
          <small class="feedback-error" *ngIf="showErrorMessage">{{ errorMessage }}
          </small>
        </div>
      </div>
      <!-- /Code Form -->
    </div>
    <div *ngIf="!forcePhoneInput && firstStep" class="custom-control custom-checkbox message-row col-12">
      <input type="checkbox" (change)="checkboxChange($event)" />
      <span>No volver a mostrar este mensaje</span>
    </div>

    <hr />

    <!-- Buttons -->
    <div class="text-center">
      <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="!buttonEnabled()">
        <span *ngIf="showSpinner">
          <mat-progress-spinner mode="indeterminate" diameter="25" class="phone-spinner" color="warn">
          </mat-progress-spinner>
        </span>
        <span *ngIf="!showSpinner">{{ submitButtonLabel }}</span>
      </button>
      <button *ngIf="!firstStep" type="button" class="btn btn-link" (click)="sendNewCode()">
        <span *ngIf="showSpinnerReenviar">
          <mat-progress-spinner mode="indeterminate" diameter="25" class="phone-spinner" color="warn">
          </mat-progress-spinner>
        </span>
        <span *ngIf="!showSpinnerReenviar">Reenviar código</span>
      </button>
      <button *ngIf="forcePhoneInput" type="button" class="btn btn-link" (click)="userDataService.logoutUser()">
        Cerrar Sesión
      </button>
    </div>
    <!-- /Buttons -->
  </form>
</div>