<div [ngClass]="{ fullscreen }">
  <div class="arrow"></div>
  <div class="filler"></div>
  <div class="panel">
    <div class="title-bar">
      <button class="close-btn" *ngIf="fullscreen" mat-icon-button (click)="close()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <h2>Notificaciones</h2>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu" backdropClass="blurred-backdrop">
        <div class="options-menu">
          <span>Opciones</span>
          <button mat-menu-item class="menu-opt" (click)="goToAutogestion()">
            <span>Abrir autogestión de notificaciones</span>
          </button>
        </div>
      </mat-menu>
    </div>
    <div class="notifications-list" [ngClass]="{ 'justify-content-center': !list.length }">
      <div class="w-100 flex-grow-1" *ngIf="list.length">
        <div class="notifications-group" *ngIf="listGroups.today.length">
          <span class="today">Hoy</span>
          <app-notification *ngFor="let item of listGroups.today; let i = index" [notification]="item"
            (click)="goToDestination(item)" (keypress)="goToDestination(item)">
          </app-notification>
        </div>
        <div class="notifications-group" *ngIf="listGroups.previous.length">
          <span>Anteriores</span>
          <app-notification *ngFor="let item of listGroups.previous; let i = index" [notification]="item"
            [oldNotification]="true" (click)="goToDestination(item)" (keypress)="goToDestination(item)">
          </app-notification>
        </div>
      </div>
      <div class="notification-empty" *ngIf="listStatus === ListStatus.EMPTY && !list.length">
        <div>
          <mat-icon svgIcon="globo_icono"></mat-icon>
          <b>Sin notificaciones</b>
          <p>Tal vez ocurra algo interesante en unas horas.</p>
        </div>
      </div>
      <div *ngIf="listStatus === ListStatus.LOADING" class="paginator-spinner">
        <mat-progress-spinner mode="indeterminate" diameter="19"></mat-progress-spinner>
      </div>
    </div>
  </div>
</div>