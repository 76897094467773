<div class="backdrop" (click)="closeModal()">
  <div *ngIf="loading">
    <mat-progress-spinner color="cornflower" mode="indeterminate" diameter="72" strokeWidth="6"></mat-progress-spinner>
  </div>

  <div class="notification-wrapper" [hidden]="loading" (click)="$event.stopPropagation()">
    <button mat-icon-button class="btn close-modal-button" (click)="closeModal()">
      <mat-icon title="Cerrar">close</mat-icon>
    </button>
    <ng-container *ngIf="!loadingFailed">
      <div class="notification-header">
        <h1 class="text-center p-0">{{ title }}</h1>
        <p class="text-center p-0">
          {{ description }}
        </p>
      </div>

      <div class="notification-body" *ngIf="resources?.length">
        <div class="image-container" *ngFor="let asset of resources; index as i">
          <img
            [srcset]="asset.srcset"
            [src]="asset.src"
            (load)="resourceLoaded(i)"
            (error)="resourceLoaded(i, true)"
            [hidden]="asset.error"
          />
          <mat-icon *ngIf="asset.error">image_not_supported</mat-icon>
        </div>
      </div>

      <div class="notification-footer">
        <button mat-button color="cerulean-blue" class="btn btn-primary w-100" (click)="actionClick()">
          {{ actionLabel }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="loadingFailed">
      <div *ngIf="loadingFailed" class="loading-error notification-body">
        <mat-icon>dangerous</mat-icon>
        <h3 class="font-weight-bold">Contenido no disponible</h3>
        <div>Ocurrió un error al cargar la notificación, por favor intente nuevamente más tarde.</div>
      </div>
      <div class="notification-footer w-100">
        <button mat-button color="cerulean-blue" class="w-100 btn btn-primary" (click)="closeModal()">cerrar</button>
      </div>
    </ng-container>
  </div>
</div>
