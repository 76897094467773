import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

enum PUBLIC_PATH {
  IMAGES = 'images',
}

@Injectable({
  providedIn: 'root',
})
export class PublicFilesService {
  private api: string;

  constructor() {
    this.api = environment.API_URL;
  }

  getPublicImageFullPath(filename: string) {
    return `${this.api}${PUBLIC_PATH.IMAGES}/${filename}`;
  }
}
