/**
 * It set ups the infinite scroll logic to be used in the grids that require it.
 */
export function setupInfiniteScroll(context, containerClassName) {
  const content = context.elementRef.nativeElement.querySelector(containerClassName);
  context.infiniteScrollListener = () => {
    clearTimeout(context.infiniteScrollTO);
    context.infiniteScrollTO = setTimeout(() => scrolling(context, content), 50);
  };
  content.addEventListener('touchmove', context.infiniteScrollListener, false);
  content.addEventListener('scroll', context.infiniteScrollListener, false);
}

function scrolling(context, content) {
  const max = content.scrollHeight - content.clientHeight;
  if (content.scrollTop > max * 0.95) {
    if (context.infiniteScrollFlag) {
      context.infiniteScrollFlag = false;
      context.infiniteScroll();
    }
  }
}

export function removeInfiniteScroll(context, containerClassName): void {
  window.document.querySelector(containerClassName).removeEventListener('touchmove', context.infiniteScrollListener);
  window.document.querySelector(containerClassName).removeEventListener('scroll', context.infiniteScrollListener);
}
