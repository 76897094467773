import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe, PlatformLocation } from '@angular/common';
import {
  AfterViewInit, Component,
  ElementRef,
  EventEmitter, OnDestroy, Output,
} from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { routeDictionary } from 'src/app/_core/dictionary/routes.dictionary';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { removeInfiniteScroll, setupInfiniteScroll } from 'src/app/_shared/helpers/infiniteScroll';
import { SubSink } from 'subsink';
import { Notification } from '../../models/notification.model';
import { NotificationsService } from '../../services/notifications/notifications.service';


interface NotificationsGroups {
  today: Notification[];
  previous: Notification[];
}

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent implements AfterViewInit, OnDestroy {
  /**
   * Para indicar al componente principal cuándo ocultar este panel.
   */
  @Output() closePanel: EventEmitter<boolean> = new EventEmitter();

  /**
   * Variables de la lista de notificaciones.
   */
  ListStatus = TableStatusEnum;

  listStatus: TableStatusEnum = TableStatusEnum.LOADING;

  listAttr = {
    page: 1,
    count: 5,
  };

  cuitChangesSubscription: Subscription;

  listGroups: NotificationsGroups = {
    today: [],
    previous: [],
  };

  fullscreen: boolean;

  leaving = false;

  private allNotifications: Notification[] = [];

  /**
   * Configuration del Snack Bar.
   */
  private snackbarConfig: MatSnackBarConfig<any> = {
    duration: 4000,
    verticalPosition: 'bottom',
  };

  /**
   * Variables del Scroll Infinito.
   */
  private CONTAINER_CLASS_NAME = '.notifications-list';

  private infiniteScrollFlag = true;

  private listTotal = 0;

  /**
   * Para usar en takeUntil y cancelar solicitudes en curso cuando ya no sean necesarias. Por ejemplo:
   * al cambiar de CUIT, o al intentar cargar una nueva página y la solicitud anterior aún no se ha completado.
   */
  private listRequestUnsubber: Subject<any> = new Subject();

  private readonly subs = new SubSink();

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private elementRef: ElementRef,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private cuitChangedService: CuitChangedService,
    private readonly userDataService: UserDataService,
    private readonly datePipe: DatePipe,
    private readonly bo: BreakpointObserver,
    private readonly platformLocation: PlatformLocation,
  ) {
    this.getListData();
    // Mostrar el panel de notificaciones en modo fullscreen si la pantalla es muy chica
    this.subs.sink = this.bo.observe([Breakpoints.HandsetPortrait]).subscribe(breakpoint => {
      this.fullscreen = breakpoint.matches;

      // Se agregar Query Params para el panel de notificaciones cuando está en modo fullscreen.
      // para agregar al historial de navegacion para manejar el panel de notificaciones.
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { notificationPanel: this.fullscreen || null },
        queryParamsHandling: 'merge'
      });
    });

    this.platformLocation.onPopState(() => {
      if (this.fullscreen) {
        this.close();
      }
    });
  }

  get list() {
    return this.allNotifications;
  }

  /**
   * Al asignar un valor a la lista se calculan los grupos de notificaciones para "Hoy" y "Anteriores"
   */
  set list(val: Notification[]) {
    this.allNotifications = val;

    const today: Notification[] = [];
    const previous: Notification[] = [];

    this.allNotifications.forEach((notification) => {
      const fechaNotificacion = this.datePipe.transform(notification.fecha, 'dd/MM/YYYY');
      const fechaHoy = this.datePipe.transform(new Date(), 'dd/MM/YYYY');

      const targetArray = fechaNotificacion === fechaHoy ? today : previous;
      targetArray.push(notification);
    });

    this.listGroups = { today, previous };
  }

  ngAfterViewInit() {
    setupInfiniteScroll(this, this.CONTAINER_CLASS_NAME);
    this.cuitChangesSubscription = this.cuitChangedService.activeCuitChanged
      .subscribe(() => this.getListData());
  }

  ngOnDestroy(): void {
    removeInfiniteScroll(this, this.CONTAINER_CLASS_NAME);
    this.listRequestUnsubber.next(null);
    this.cuitChangesSubscription?.unsubscribe();
    this.subs.unsubscribe();

    // Se borra la query params al cerrar el Panel de notificaciones
    if (this.router.url === routeDictionary.autogestion.notificaciones) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { notificationPanel: null },
        queryParamsHandling: 'merge'
      });
    }
  }

  /**
   * Carga una nueva página de notificaciones. Si appendData es verdadero, los datos devueltos se agregarán a la lista.
   * De lo contrario, la lista se restablecerá y se completará con los datos devueltos.
   * @param appendEvent appendEvent
   */
  getListData(appendEvent?: boolean): void {
    // Cancelar solicitudes anteriores si aún no han regresado
    this.listRequestUnsubber.next(null);

    this.listStatus = TableStatusEnum.LOADING;

    if (!appendEvent) {
      this.list = [];
      this.resetPage();
    }

    this.notificationsService
      .getNotifications(
        this.userDataService.getActiveCUIT(),
        this.listAttr.page,
        this.listAttr.count
      )
      .pipe(takeUntil(this.listRequestUnsubber))
      .subscribe(
        (res: any) => {
          if (appendEvent) {
            this.appendData(res);
          } else {
            this.reloadData(res);
          }
          this.listAttr.page += 1;
          this.infiniteScrollFlag = true;
        },
        (err: any) => {
          console.error(err);
          this.listStatus = TableStatusEnum.ERROR;
          this.infiniteScrollFlag = true;
        }
      );
  }

  resetPage(): void {
    this.listAttr.page = 1;
  }

  reloadData(data: any): void {
    this.list = data.notificaciones;

    this.listTotal = data.cantidadTotal;

    if (data.notificaciones.length === 0) {
      this.listStatus = TableStatusEnum.EMPTY;
    } else {
      this.listStatus = TableStatusEnum.DONE;
    }
  }

  appendData(data: any): void {
    this.list = [
      ...this.list,
      ...data.notificaciones,
    ];

    this.listTotal = data.cantidadTotal;

    if (data.notificaciones.length === 0) {
      this.listStatus = TableStatusEnum.NOMORERESULTS;
    } else {
      this.listStatus = TableStatusEnum.DONE;
    }
  }

  /**
   * Se llama cuando el usuario llega al final de la lista mientras se desplaza.
   */
  infiniteScroll(): void {
    if (this.showMoreData()) {
      this.getListData(true);
    } else {
      this.listStatus = TableStatusEnum.NOMORERESULTS;
      this.infiniteScrollFlag = true;
    }
  }

  /**
   * Devuelve verdadero o falso dependiendo de si aún no hemos terminado de cargar todas las notificaciones disponibles.
   */
  showMoreData(): boolean {
    if (this.list.length >= this.listTotal) {
      this.snackBar.open('Se ha alcanzado el final de la lista.', 'Cerrar', this.snackbarConfig);
      return false;
    }

    return true;
  }

  /**
   * Navega a la cuadrícula especificada por el módulo y los ID de la cuadrícula.
   * @param notification notification data
   */
  goToDestination(notification: Notification) {
    const userId = this.userDataService.getActiveUserId();
    this.notificationsService.handleNotification(notification, userId);
    this.close();
  }

  goToAutogestion() {
    this.close();
    this.router.navigateByUrl(routeDictionary.autogestion.notificaciones);
  }

  close() {
    this.leaving = true;
    this.closePanel.emit(true);
  }
}
