import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsPanelComponent } from './components/notifications-panel/notifications-panel.component';
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component';
import { NotificationNewFeaturesComponent } from './components/notification-new-features/notification-new-features.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
  NotificationsPanelComponent,
  NotificationSnackbarComponent,
  NotificationNewFeaturesComponent,
  NotificationComponent,
  ],
  imports: [
  CommonModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  ],
  exports: [
  NotificationsPanelComponent,
  NotificationSnackbarComponent,
  NotificationNewFeaturesComponent,
  ]
  })
export class NotificationsModule { }
